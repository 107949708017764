import React from 'react';
import { BrowserRouter, Routes, Route, Link } from 'react-router-dom';
import PriceAnalysis from './components/PriceAnalysis';
import PlantIdentifier from './components/PlantIdentifier';

const Home = () => (
  <div className="w-full max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
    <div className="bg-white rounded-lg shadow-lg p-4 sm:p-6 lg:p-8 max-w-2xl mx-auto text-center">
      <h2 className="text-xl sm:text-2xl font-semibold mb-2 sm:mb-4 text-[#377E62]">Welcome to Keboon AI</h2>
      <p className="text-gray-600 mb-4 sm:mb-8 text-sm sm:text-base">Exploring the future of AI technology</p>
      
      <div className="flex flex-col sm:flex-row gap-3 sm:gap-4 justify-center">
        <a 
          href="https://keboon.net" 
          className="px-4 sm:px-6 py-2 bg-[#377E62] text-white rounded-lg hover:bg-[#52AE77] transition-colors text-sm sm:text-base"
        >
          Main Website
        </a>
        <a 
          href="https://app.keboon.net" 
          className="px-4 sm:px-6 py-2 bg-[#52AE77] text-white rounded-lg hover:bg-[#377E62] transition-colors text-sm sm:text-base"
        >
          Mobile App
        </a>
        <Link 
          to="/price-analysis"
          className="px-4 sm:px-6 py-2 bg-[#377E62] text-white rounded-lg hover:bg-[#52AE77] transition-colors text-sm sm:text-base"
        >
          Price Analysis
        </Link>
        <Link 
          to="/PlantIdentifier"
          className="px-4 sm:px-6 py-2 bg-[#52AE77] text-white rounded-lg hover:bg-[#377E62] transition-colors text-sm sm:text-base"
        >
          Plant Identifier
        </Link>
      </div>
    </div>

    <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 sm:gap-6 mt-8 sm:mt-12 max-w-4xl mx-auto">
      <div className="bg-white p-4 sm:p-6 rounded-lg shadow-md border-l-4 border-[#377E62]">
        <h3 className="text-lg sm:text-xl font-semibold mb-2 text-[#377E62]">AI Solutions</h3>
        <p className="text-gray-600 text-sm sm:text-base">Cutting-edge artificial intelligence technology for your business needs</p>
      </div>
      <div className="bg-white p-4 sm:p-6 rounded-lg shadow-md border-l-4 border-[#52AE77]">
        <h3 className="text-lg sm:text-xl font-semibold mb-2 text-[#52AE77]">Innovation</h3>
        <p className="text-gray-600 text-sm sm:text-base">Transforming ideas into intelligent solutions</p>
      </div>
    </div>
  </div>
);

const Layout = ({ children }) => (
  <div className="min-h-screen flex flex-col bg-gray-50">
    <header className="bg-[#377E62] text-white py-4 sm:py-6 lg:py-8 sticky top-0 z-50">
      <div className="container mx-auto px-4 text-center">
        <h1 className="text-2xl sm:text-3xl lg:text-4xl font-bold mb-1 sm:mb-2">Keboon AI</h1>
        <p className="text-slate-300 text-sm sm:text-base">Artificial Intelligence Solutions</p>
      </div>
    </header>
    
    <main className="flex-1 w-full py-6 sm:py-8 lg:py-12">
      {children}
    </main>
    
    <footer className="bg-[#377E62] text-white py-3 sm:py-4 text-center text-sm sm:text-base">
      <p>&copy; 2024 Keboon. All rights reserved.</p>
    </footer>
  </div>
);

const App = () => {
  return (
    <BrowserRouter>
      <Layout>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/price-analysis" element={<PriceAnalysis onBack={() => window.history.back()} />} />
          <Route path="/PlantIdentifier" element={<PlantIdentifier onBack={() => window.history.back()} />} />
        </Routes>
      </Layout>
    </BrowserRouter>
  );
};

export default App;