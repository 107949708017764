import React, { useState, useRef, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCamera, faUpload, faSpinner } from '@fortawesome/free-solid-svg-icons';
import * as tf from '@tensorflow/tfjs';
import * as mobilenet from '@tensorflow-models/mobilenet';
import axios from 'axios';

const PlantIdentifier = () => {
  const [image, setImage] = useState(null);
  const [previewUrl, setPreviewUrl] = useState(null);
  const [loading, setLoading] = useState(false);
  const [results, setResults] = useState(null);
  const [error, setError] = useState(null);
  const [model, setModel] = useState(null);
  const [modelLoading, setModelLoading] = useState(true);
  const fileInputRef = useRef(null);
  const videoRef = useRef(null);
  const streamRef = useRef(null);
  const [showCamera, setShowCamera] = useState(false);

  // Load MobileNet model on component mount
  useEffect(() => {
    const loadModel = async () => {
      try {
        setModelLoading(true);
        const loadedModel = await mobilenet.load();
        setModel(loadedModel);
        setModelLoading(false);
      } catch (err) {
        setError('Error loading identification model. Please try again later.');
        setModelLoading(false);
        console.error('Model loading error:', err);
      }
    };
    loadModel();

    // Cleanup function to handle component unmount
    return () => {
      if (streamRef.current) {
        stopCamera();
      }
    };
  }, []);

  const handleFileSelect = (e) => {
    const file = e.target.files[0];
    if (file) {
      if (file.type.startsWith('image/')) {
        setImage(file);
        setPreviewUrl(URL.createObjectURL(file));
        setResults(null);
        setError(null);
      } else {
        setError('Please select an image file.');
      }
    }
  };

  const handleCameraCapture = async () => {
    try {
      streamRef.current = await navigator.mediaDevices.getUserMedia({ 
        video: { 
          facingMode: 'environment',
          width: { ideal: 1920 },
          height: { ideal: 1080 }
        } 
      });
      videoRef.current.srcObject = streamRef.current;
      setShowCamera(true);
      setError(null);
    } catch (err) {
      setError('Error accessing camera. Please try uploading an image instead.');
      console.error('Camera error:', err);
    }
  };

  const takePhoto = () => {
    const video = videoRef.current;
    const canvas = document.createElement('canvas');
    canvas.width = video.videoWidth;
    canvas.height = video.videoHeight;
    const ctx = canvas.getContext('2d');
    ctx.drawImage(video, 0, 0);
    canvas.toBlob((blob) => {
      setImage(blob);
      setPreviewUrl(URL.createObjectURL(blob));
      setShowCamera(false);
      stopCamera();
      setResults(null);
      setError(null);
    }, 'image/jpeg', 0.8);
  };

  const stopCamera = () => {
    if (streamRef.current) {
      streamRef.current.getTracks().forEach(track => track.stop());
      streamRef.current = null;
    }
    setShowCamera(false);
  };

  const identifyPlant = async () => {
    if (!image) {
      setError('Please select or capture an image first.');
      return;
    }

    if (!model) {
      setError('Plant identification model is not ready. Please try again.');
      return;
    }

    setLoading(true);
    setError(null);
    setResults(null);

    try {
      // Create an HTML image element from the uploaded image
      const img = new Image();
      img.src = previewUrl;
      await new Promise((resolve, reject) => {
        img.onload = resolve;
        img.onerror = reject;
      });

      // Classify the image using MobileNet
      const predictions = await model.classify(img, 5);

      // Process and filter predictions to show only plant-related results
      const plantKeywords = ['plant', 'flower', 'tree', 'leaf', 'herb', 'grass', 'shrub', 'vine'];
      const processedResults = predictions
        .filter(pred => 
          plantKeywords.some(keyword => 
            pred.className.toLowerCase().includes(keyword)
          )
        )
        .map(pred => ({
          name: pred.className.split(',')[0],
          scientificName: pred.className,
          confidence: pred.probability * 100,
          observedOn: new Date().toLocaleDateString(),
          location: 'Local analysis',
          image: null // Will be populated from iNaturalist if available
        }));

      if (processedResults.length === 0) {
        setError('No plant species detected in the image. Please try another image.');
        setLoading(false);
        return;
      }

      // Fetch additional information from iNaturalist for each result
      const enrichedResults = await Promise.all(
        processedResults.map(async (result) => {
          try {
            const iNatResponse = await axios.get('https://api.inaturalist.org/v1/taxa', {
              params: {
                q: result.name.split(' ')[0], // Use first word of name for better matches
                per_page: 1
              }
            });

            if (iNatResponse.data.results.length > 0) {
              const iNatInfo = iNatResponse.data.results[0];
              return {
                ...result,
                scientificName: iNatInfo.name || result.scientificName,
                image: iNatInfo.default_photo?.medium_url || null,
                description: iNatInfo.wikipedia_summary || null
              };
            }
            return result;
          } catch (err) {
            console.error('Error fetching iNaturalist data:', err);
            return result;
          }
        })
      );

      setResults(enrichedResults);
    } catch (err) {
      setError('Error identifying plant. Please try again.');
      console.error('Analysis Error:', err);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="max-w-lg mx-auto p-4">
      <h1 className="text-2xl font-bold text-center mb-6 text-[#377E62]">Plant Identifier</h1>

      {modelLoading && (
        <div className="text-center mb-4 text-gray-600">
          <FontAwesomeIcon icon={faSpinner} spin className="mr-2" />
          Loading plant identification model...
        </div>
      )}

      <div className="mb-6">
        <div className="flex justify-center gap-4 mb-4">
          <button
            onClick={() => fileInputRef.current.click()}
            disabled={modelLoading}
            className="bg-[#377E62] text-white px-4 py-2 rounded-lg flex items-center gap-2 hover:bg-[#52AE77] disabled:bg-gray-400"
          >
            <FontAwesomeIcon icon={faUpload} />
            Upload Image
          </button>
          <button
            onClick={handleCameraCapture}
            disabled={modelLoading}
            className="bg-[#52AE77] text-white px-4 py-2 rounded-lg flex items-center gap-2 hover:bg-[#377E62] disabled:bg-gray-400"
          >
            <FontAwesomeIcon icon={faCamera} />
            Take Photo
          </button>
        </div>
        <input
          type="file"
          accept="image/*"
          onChange={handleFileSelect}
          ref={fileInputRef}
          className="hidden"
        />
      </div>

      {showCamera && (
        <div className="relative mb-4">
          <video
            ref={videoRef}
            autoPlay
            playsInline
            className="w-full rounded-lg"
          />
          <button
            onClick={takePhoto}
            className="absolute bottom-4 left-1/2 transform -translate-x-1/2 bg-red-600 text-white px-6 py-2 rounded-full hover:bg-red-700"
          >
            Capture
          </button>
        </div>
      )}

      {previewUrl && !showCamera && (
        <div className="mb-4">
          <img
            src={previewUrl}
            alt="Preview"
            className="w-full h-64 object-cover rounded-lg"
          />
        </div>
      )}

      {previewUrl && !showCamera && (
        <button
          onClick={identifyPlant}
          disabled={loading || modelLoading}
          className="w-full bg-[#377E62] text-white py-2 rounded-lg mb-4 flex items-center justify-center gap-2 hover:bg-[#52AE77] disabled:bg-gray-400"
        >
          {loading ? (
            <>
              <FontAwesomeIcon icon={faSpinner} spin />
              Identifying...
            </>
          ) : (
            'Identify Plant'
          )}
        </button>
      )}

      {error && (
        <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded-lg mb-4">
          {error}
        </div>
      )}

      {results && (
        <div className="bg-white rounded-lg shadow p-4">
          <h2 className="text-xl font-semibold mb-4 text-[#377E62]">Results</h2>
          {results.map((result, index) => (
            <div key={index} className="mb-4 p-4 bg-gray-50 rounded-lg">
              <div className="flex flex-col md:flex-row gap-4">
                {result.image && (
                  <img 
                    src={result.image} 
                    alt={result.name} 
                    className="w-full md:w-32 h-32 object-cover rounded-lg"
                  />
                )}
                <div className="flex-1">
                  <h3 className="font-semibold text-lg text-[#377E62]">{result.name}</h3>
                  {result.scientificName && (
                    <p className="text-gray-600 italic">{result.scientificName}</p>
                  )}
                  <p className="text-sm text-gray-500">
                    Confidence: {result.confidence.toFixed(1)}%
                  </p>
                  {result.description && (
                    <p className="text-sm text-gray-600 mt-2">
                      {result.description.substring(0, 200)}...
                    </p>
                  )}
                </div>
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default PlantIdentifier;